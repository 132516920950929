@use "base/layer-priority.scss";

@import url("primeicons/primeicons.css") layer(primeng-base);
@import url("primeng/resources/primeng.min.css") layer(primeng-base);

@layer tailwind-base {
  @tailwind base;
}

@import url("mapbox-gl/dist/mapbox-gl.css") layer(mapbox);
